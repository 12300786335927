function customSelectMultiple(el) {
  let parentElement = null;
  let left = null; // available items panel
  let right = null; // chosen items select box
  let data = null;
  let label = [];
  let cache = [];
  let selected = [];
  let notFound = [];
  let leftLastActiveItemId = null;
  let leftFilterText = '';
  let rightFilterText = '';
  let tappedItem = 0;
  let externalFilter = [];
  let hiddenItemsSelector = ":scope > div:not(.hide):not(.mask)";
  let exactMatchFilter = false;
  let progressCoefficient = 0;
  let progressBar = null;

  const updateCount = () => {
    let leftCount = left.querySelectorAll(hiddenItemsSelector).length;
    if (leftFilterText) {
      /*
      const leftFiltredCount = left.querySelectorAll(".mask").length;
      if (leftFiltredCount) {
        leftCount += ` <span class="text-danger fw-normal">(${leftFiltredCount} not visible)</span>`;
      }
      */
      if (notFound.length) {
        leftCount += ` | <span class="text-danger fw-normal notFound" data-bs-toggle="tooltip" data-bs-html="true" 
        data-bs-placement="right" title="${notFound.join("<br>")}">${notFound.length} not found <i class="fa-solid fa-circle-question"></i></span>`;
      }
    }
    parentElement.querySelector(".left-count").innerHTML = leftCount;

    let rightCount = right.querySelectorAll("option:not(.mask)").length;
    if (rightFilterText) {
      const rightFiltredCount = right.querySelectorAll(".mask").length;
      if (rightFiltredCount) {
        rightCount += ` <span class="text-danger fw-normal">(${rightFiltredCount} not visible)</span>`;
      }
    }
    parentElement.querySelector(".right-count").innerHTML = rightCount;
  };

  const chooseItem = (id) => {
    const newOption = document.createElement("option");
    newOption.text = label[id];
    newOption.value = id;
    right.appendChild(newOption);
  };

  const tapHandler = (event) => {
    if (!tappedItem) {
      tappedItem = event.target.dataset.id;
      setTimeout(function () {
        tappedItem = 0;
      }, 300);
      return false;
    }
    event.preventDefault();
    if (tappedItem == event.target.dataset.id) {
      event.target.classList.remove("active");
      event.target.classList.add("hide");
      chooseItem(event.target.dataset.id);

      updateCount();
    }
  };

  const addActionsRightPanel = () => {
    right.addEventListener("dblclick", (event) => {
      if (event.target.matches("option")) {
        const item = event.target;
        const id = item.value;
        selected = selected.filter(item => item !== id);
        cache[id].classList.remove('hide');
        item.remove();
        applyFilter();
      }
    });
  };

  const resetLeftSelection = () => {
    cache.forEach(item => {
        item.classList.remove('active');
    });
  }

  const addActionsLeftPanel = () => {
    cache.forEach(item => {
      item.addEventListener('click', (e) => {
        if (e.ctrlKey || e.metaKey) {
          item.classList.toggle('active');
        }
        else if (e.shiftKey && leftLastActiveItemId) {
          resetLeftSelection();

          const indexStart = Array.prototype.indexOf.call(left.children, cache[leftLastActiveItemId]);
          const indexEnd = Array.prototype.indexOf.call(left.children, item);
          if (indexStart < indexEnd) {
            for (let i = indexStart; i <= indexEnd; i++) {
              left.children[i].classList.add('active');
            }
          } else {
            for (let i = indexStart; i >= indexEnd; i--) {
              left.children[i].classList.add('active');
            }
          }
        }
        else {
          resetLeftSelection();

          item.classList.toggle('active');
        }

        leftLastActiveItemId = item.dataset.id;
      });
      item.addEventListener("dblclick", () => {
        item.classList.remove('active');
        item.classList.add('hide');
        chooseItem(item.dataset.id);

        updateCount();
      });
      item.addEventListener("touchstart", tapHandler);
    });
  };

  const middleButtonAction = event => {
    let btn = event.target;
    if (btn.tagName === 'I') {
      // if it is child then select parent
      btn = btn.parentNode;
    }

    switch (btn.dataset.action) {
      case 'move-selected-right':
        left.querySelectorAll('.active').forEach(item => {
          item.classList.remove('active');
          item.classList.add('hide');
          selected.push(item.dataset.id);
          chooseItem(item.dataset.id);
        });
        break;

      case 'move-all-right':
        left.querySelectorAll(hiddenItemsSelector).forEach(item => {
          item.classList.remove('active');
          item.classList.add('hide');
          selected.push(item.dataset.id);
          chooseItem(item.dataset.id);
        });
        break;

      case 'move-selected-left':
        for (option of right.selectedOptions) {
          selected = selected.filter(item => item !== option.value);
          cache[option.value].classList.remove('hide');
        }
        while (right.selectedOptions.length > 0) {
          right.selectedOptions[0].remove();
        }
        applyFilter();
        break;

      case 'move-all-left':
        right.querySelectorAll('option:not(.mask)').forEach(option => {
          selected = selected.filter(e => e !== option.value)
          cache[option.value].classList.remove('hide');
          option.remove();
        });
        applyFilter();
        break;
    }

    leftLastActiveItemId = null;
    updateCount();
  };

  const validateChosen = () => {
    if (right.length === 0) {
      right.setCustomValidity("Please select an item");
    } else {
      right.setCustomValidity("");
    }
  }

  const observer = new MutationObserver(mutations => {
    for (const mutation of mutations) {
      if (mutation.type === 'childList') {
        validateChosen();
      }
    }
  });

  const resetFilter = () => {
    leftFilterText = '';
    parentElement.querySelector('.left-filter').value = '';
    cache.forEach(item => {
      if (item.masked) {
        item.classList.remove('mask');
      }
      item.querySelector('.match').innerHTML = '';
    });

    progressBar.style.width = 0;
    notFound = [];
    updateCount();
  }

  const changeFilter = (e) => {
    const btn = e.target.tagName === "I" ? e.target.closest('span') : e.target;
    exactMatchFilter = !exactMatchFilter;
    if (exactMatchFilter) {
      btn.classList.add('text-success');
    } else {
      btn.classList.remove('text-success');
    }

    applyFilter()
  }

  const applyFilter = () => {
    if (leftFilterText === '') {
      resetFilter();
      return;
    }

    let textMatched = new Set();
    const textArray = splitMultiValueText(leftFilterText);
    const rgx = textArray.join("|");
    const regex = new RegExp(rgx.toLowerCase());

    progressBar.style.width = 0;
    let currentIteration = 0;
    for (const key in data) {
      currentIteration++;
      progressBar.style.width = (currentIteration * progressCoefficient) + '%';

      if (selected.indexOf(key) !== -1) {
        continue;
      }

      const item = cache[key];
      let match = '';
      item.masked = true;
      Object.entries(data[key]).forEach(([property, value]) => {
        let phrase = '';
        if (exactMatchFilter) {
          if (textArray.includes(value)) {
            phrase = value;
          }
        } else {
          const found = value.toLowerCase().match(regex);
          if (found) {
            phrase = found[0];
          }
        }

        if (phrase) {
          textMatched.add(phrase.toLowerCase());
          item.masked = false;
          match += `<span class="badge bg-secondary me-1">${property}: ${value}</span>`;
        }
      });

      const matchBox = item.querySelector('.match');
      if (item.masked) {
        item.classList.add('mask');
      } else {
        item.classList.remove('mask');
        matchBox.innerHTML = match;
      }
    }

    notFound = [...textArray].filter(item => !textMatched.has(item.toLowerCase()));

    updateCount();
  }

  const debouncedApplyFilter = debounce(applyFilter, 300);

  const resetRightFilter = () => {
    rightFilterText = '';
    parentElement.querySelector('.right-filter').value = '';
    right.querySelectorAll('option').forEach(item => {
      item.classList.remove('mask');
    });

    updateCount();
  }

  const applyRightFilter = () => {
    if (rightFilterText === '') {
      resetRightFilter();
      return;
    }

    const tokens = rightFilterText.toLowerCase().split(/\s+/);
    selected.forEach((value) => {
      let visible = true;
      for (const token of tokens) {
        if (!label[value].toLowerCase().includes(token)) {
          visible = false;
          break; // Once the first token isn't found we're done
        }
      }

      const option = right.querySelector(`option[value="${value}"]`);
      if (visible) {
        option.classList.remove('mask');
      } else {
        option.classList.add('mask');
      }
    });

    updateCount();
  }

  const debouncedApplyRightFilter = debounce(applyRightFilter, 300);

  const initExternalFilter = () => {
    document.querySelectorAll('.csm-external-filter').forEach(function (el) {
      const cssClass = el.dataset.class || 'off';

      externalFilter[el.id] = {
        'field': el.dataset.field,
        'data': JSON.parse(document.getElementById(el.dataset.source).textContent),
        'class': cssClass,
      };

      hiddenItemsSelector += `:not(.${cssClass})`;

      if (el.type === 'checkbox') {
        el.addEventListener('change', actionExternalFilterCheckbox);
      }
    });

    Object.entries(externalFilter).forEach(([id, filter]) => {
      for (const key in data) {
        if (!filter.data.includes(data[key][filter.field])) {
          cache[key].classList.add(filter.class);
        }
      }
    });
  }

  const actionExternalFilterCheckbox = (event) => {
    const el = event.target;
    const filter = externalFilter[el.id];

    if (el.checked) {
      left.querySelectorAll(":scope > div").forEach(item => {
        item.classList.remove(filter.class);
      });
    } else {
      for (const key in data) {
        if (!filter.data.includes(data[key][filter.field])) {
          cache[key].classList.add(filter.class);
        }
      }
    }

    updateCount();
  }

  const init = () => {
    parentElement = el.closest('.custom-select-multiple');
    right = el;
    left = parentElement.querySelector('.panel-body');
    data = JSON.parse(document.getElementById(right.id + '_data').textContent);

    progressCoefficient = 100 / Object.keys(data).length;
    progressBar = parentElement.querySelector('.progress-bar');

    // get labels for later and initial selected options
    left.querySelectorAll(':scope > div').forEach(function (item) {
      cache[item.dataset.id] = item;
      label[item.dataset.id] = item.querySelector('.label').innerText;
      if (item.classList.contains('hide')) {
        selected.push(item.dataset.id);
      }
    });

    // look for external filter
    initExternalFilter();

    // set right panel items selected when form submitted
    right.closest('form').addEventListener('submit', function () {
      for (const option of right.options) {
        option.selected = true;
      }
    });

    // add custom validation
    right.required = false;
    right.addEventListener('change', validateChosen);
    observer.observe(right, { childList: true });

    // set right panel items double click action
    addActionsRightPanel();

    // set left panel items selectable
    addActionsLeftPanel();

    // set middle panel button actions
    parentElement.querySelectorAll('span[role="button"]').forEach(item => {
      item.addEventListener('click', middleButtonAction, false);
    });

    // left filter
    parentElement.querySelector('.left-filter').addEventListener('keyup', function () {
      leftFilterText = this.value;
      debouncedApplyFilter();
    });
    parentElement.querySelector('.left-filter-reset').addEventListener('click', resetFilter);
    parentElement.querySelector('.left-filter-match').addEventListener('click', changeFilter);

    // right filter
    parentElement.querySelector('.right-filter').addEventListener('keyup', function () {
      rightFilterText = this.value;
      debouncedApplyRightFilter();
    });
    parentElement.querySelector('.right-filter-reset').addEventListener('click', resetRightFilter);

    // display item count
    updateCount();
  };

  init();
}

window.addEventListener('load', function (e) {
  document.querySelectorAll('select.customselectmultiple').forEach(customSelectMultiple);
});

(function ($) {
  $(function () {
    $('body').tooltip({
      selector: '[data-bs-toggle="tooltip"]'
    });
  });
})(jQuery);

function splitMultiValueText(text) {
  const separators = /\s*,\s*|\s*;\s*|\n+|\s+/g;
  const textArray = text.split(separators);
  const filteredArray = textArray.filter((item) => item.trim() !== "");

  return filteredArray;
}

function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}
