import typeahead from 'typeahead-standalone';

const searchBarInput = document.querySelector('#searchBarInput');

(function ($) {
  $(function () {
    if (!searchBarInput) return;

    let recentPages = null;

    const item_selected_gtag = (item) => {
      if (window.gtag) {
        window.gtag('event', 'select_item', {
          item_list_id: 'search_result',
          items: [
            {
              item_id: item.id,
              item_name: item.title,
            },
          ],
        });
      }
    };

    const search_performed_gtag = (search_term) => {
      if (window.gtag) {
        window.gtag('event', 'search', { search_term });
      }
    };

    typeahead({
      input: searchBarInput,
      highlight: true,
      hint: false,
      limit: 20,
      minLength: 2,
      source: {
        local: [],
        remote: {
          url: (search_term) => {
            search_performed_gtag(search_term);
            return `${searchBarInput.dataset.source}?q=${search_term}`;
          },
          wildcard: '%QUERY',
          requestOptions: {},
          debounce: 300,
        },
        keys: ['title'],
        identity: (item) => `${item.id}${item.title}`,
      },
      onSubmit: (event, item) => {
        if (item) {
          item_selected_gtag(item);
          window.location.href = item.url;
        }
      },
      display: (item, event) => {
        if (event) {
          if (event.type === 'click' && (event.ctrlKey || event.metaKey)) {
            item_selected_gtag(item);
            window.open(item.url, '_blank');
          }
          else if (event.type === 'click' || (event.type === 'keydown' && event.key === 'Tab')) {
            item_selected_gtag(item);
            window.location.href = item.url;
          }
        }
        return `${item.title}`;
      },
      templates: {
        notFound: () => 'Nothing Found',
        loader: () =>
          '<div class="text-center"><i class="fa fa-circle-notch fa-spin fa-2x"></i></div>',
        footer: (resultSet) => {
          if (!resultSet.query) return '';
          window.searchResultSet = resultSet;
          return `${resultSet.count > resultSet.limit
            ? `Showing ${resultSet.limit} of ${resultSet.count} <a href="#" data-bs-toggle="modal" data-bs-target="#search-result-modal">See more...</a>`
            : ''
            }`;
        },
        empty: () => {
          return recentPages.length ? recentPages : `<div>No recent pages...</div>`;
        }
      },
    });

    $('#search-result-modal').on('show.bs.modal', function () {
      const modalBody = $(this).find('.modal-body');
      modalBody.empty();

      $.each(window.searchResultSet.hits, function (i, item) {
        const link = $(
          `<div><a href="${item.url}">${item.title}</a> ${item.publisher}</div>`,
        );
        link.find('a').on('click', function (e) {
          e.preventDefault();
          item_selected_gtag(item);
          window.location.href = this.href;
        });
        modalBody.append(link);
      });
    });

    $.getJSON(`${searchBarInput.dataset.recent}`, function (data) {
      recentPages = data;
    });
  });
})(jQuery);
